export enum ErrorTypes {
  required = 'required',
  unknown = 'unknown',
  maxlength = 'maxlength',
  minlength = 'minlength',
  min = 'min',
  max = 'max'
}

export enum ErrorMessages {
  required = 'This field is required',
  unknown = 'This field is invalid',
  maxlength = 'This field exceeds maximum length of ',
  minlength = 'The value length of this field is less than ',
  min = 'The value cannot be lesser than ',
  max = 'The value cannot be greater than '
}
