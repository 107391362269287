import { NgModule } from '@angular/core';
import {
  MatSnackBarModule,
  MAT_SNACK_BAR_DATA
} from '@angular/material/snack-bar';
import { AlertsComponent } from './alerts.component';
import { CommonModule } from '@angular/common';
@NgModule({
  imports: [CommonModule, MatSnackBarModule],
  declarations: [AlertsComponent],
  entryComponents: [AlertsComponent],
  providers: [{ provide: MAT_SNACK_BAR_DATA, useValue: {} }]
})
export class AlertsModule {}
