export const DEFAULT_PROPERTY_VALUE = 'value';
export const NUMBER_PROPERTY_VALUE = 'valueAsNumber';

export const ALL_PROPERTIES_VALUE = {
  number: NUMBER_PROPERTY_VALUE,
  range: NUMBER_PROPERTY_VALUE,
  date: NUMBER_PROPERTY_VALUE,
  month: NUMBER_PROPERTY_VALUE,
  week: NUMBER_PROPERTY_VALUE,
  time: NUMBER_PROPERTY_VALUE,
  'datetime-local': NUMBER_PROPERTY_VALUE,
  text: DEFAULT_PROPERTY_VALUE
};
