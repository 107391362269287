import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, startWith } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

import { replaceHyphenBySpace } from '@rappi/common';

import { Breadcrumb } from '../models/Breadcrumb';

@Injectable()
export class BreadcrumbsService {
  destination = new BehaviorSubject<string>('');
  breadcrumbs = new BehaviorSubject<Breadcrumb[]>([]);

  constructor(private readonly router: Router, private readonly activatedRoute: ActivatedRoute) {
    if (this.router) {
      this.router.events.pipe(
        filter((x) => x instanceof NavigationEnd),
        startWith({ urlAfterRedirects: this.router.url } as NavigationEnd)
      ).subscribe((event: NavigationEnd) => {
        const destination = event.urlAfterRedirects;
        const breadcrumbs = this.createBreadcrumbs(this.activatedRoute.root);
        this.destination.next(destination);
        this.breadcrumbs.next(breadcrumbs);
      });
    }
  }

  private createBreadcrumbs(route: ActivatedRoute, currentUrl = '/', breadcrumbs: Breadcrumb[] = []): Breadcrumb[] {
    const isRootBreadcrumb = breadcrumbs.length === 0;
    const children: ActivatedRoute[] = route.children;
    let res: Breadcrumb[];

    if (children.length === 0) {
      res = breadcrumbs;
    }

    for (const child of children) {
      const childPath = this.getChildPath(child, breadcrumbs);
      const nextUrl = currentUrl + childPath;
      const label =
        replaceHyphenBySpace((child.snapshot.params || {})[child.snapshot.data['paramForLabel']]) ||
        child.snapshot.data['breadcrumbs'];
      const link = child.snapshot.data['breadcrumbsLink'] ? nextUrl.replace('//', '/') : undefined;

      if ((childPath || isRootBreadcrumb) && !this.isNullOrUndefined(label)) {
        if (isRootBreadcrumb && link !== '/') {
          breadcrumbs.push({ label: '', link: '/' });
        }
        breadcrumbs.push({ label, link });
      }
      res = this.createBreadcrumbs(child, nextUrl, breadcrumbs);
    }

    return res;
  }

  private getChildPath(child: ActivatedRoute, currentBreadcrumbs: Breadcrumb[]): string {
    let childPath: string = child.snapshot.url.map((segment) => segment.path).join('/');

    if (childPath !== '') {
      childPath = currentBreadcrumbs.length === 1 ? childPath : `/${childPath}`;
    }

    return childPath;
  }

  private isNullOrUndefined(payload: any): boolean {
    return typeof payload === 'undefined' || payload == null;
  }
}
