import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { DynamicTableComponent } from './components/dynamic-table/dynamic-table.component';
import { DynamicCellsComponent } from './components/dynamic-cells/dynamic-cells.component';
import { DynamicComponentDirective } from './resources/directives/dynamic-component.directive';
import { FilterMobileRowsPipe } from './resources/pipes/filter-mobile-rows.pipe';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CustomTooltipModule } from '../custom-tooltip/custom-tooltip.module';
import { ValidateActiveRowPipe } from './resources/pipes/validate-active-row.pipe';

@NgModule({
  declarations: [
    DynamicTableComponent,
    DynamicCellsComponent,
    DynamicComponentDirective,
    FilterMobileRowsPipe,
    ValidateActiveRowPipe
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatSelectModule,
    MatTableModule,
    MatTooltipModule,
    RouterModule,
    FlexLayoutModule,
    CustomTooltipModule
  ],
  exports: [
    DynamicTableComponent,
    DynamicCellsComponent,
    DynamicComponentDirective,
    FilterMobileRowsPipe
  ]
})
export class DynamicTableModule {}
