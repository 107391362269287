import {
  Component,
  Injector,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ViewChild,
  AfterViewInit,
  ElementRef,
  HostListener,
  ViewChildren,
  QueryList,
  Renderer2,
  ChangeDetectorRef,
  Optional,
  Self,
  OnDestroy
} from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { FormElement, LazyScrollableDataSource, generateId, ColorNames } from '@rappi/common';
import { Subject, Observable, of, Subscription } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import {
  SelectKeys,
  DEFAULT_SELECT_KEYS,
  SelectConfig,
  DEFAULT_SELECT_CONFIG,
  SelectSize,
  DEFAULT_SELECT_SIZE,
  SelectGroupedItem
} from '../definitions';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { SelectionModel } from '@angular/cdk/collections';

type SelectDatasource<T> = LazyScrollableDataSource<T> | Array<T> | Array<SelectGroupedItem<T>>;

@Component({
  selector: 'one-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent<T> extends FormElement<T> implements OnInit, AfterViewInit, OnDestroy {
  index: number;
  itemHeight = 40;
  viewportItems = 4;
  viewportHeight = 160;
  maxBufferPx = 200;
  minBufferPx = 160;

  filterValue: string;
  id: string;

  private _allSelected = false;
  get allSelected(): boolean {
    return this._allSelected;
  }

  opened = false;
  defaultValue: string | T = null;
  loading: Observable<boolean>;

  ariaActive = 'aria-active';
  ariaSelected = 'aria-selected';
  ariaActiveDescendat = 'aria-activedescendant';
  valueAttr = 'data-value';
  allAttr = 'select-all';
  searchAttr = 'search';

  @Input() set dataSource(ds: SelectDatasource<T>) {
    if (this._dataSource) {
      this._subscriptions.unsubscribe();
      this._subscriptions = new Subscription();
      this.loading = this.initLazyLoader(this._dataSource);
      this._cd.detectChanges();
      this.setSubscriptions(ds);
    }
    this._dataSource = ds;
  }
  _dataSource: SelectDatasource<T>;
  private _subscriptions: Subscription = new Subscription();

  @Input() color: ColorNames;
  @Input() hiddenLabel = false;
  @Input() keys: SelectKeys = DEFAULT_SELECT_KEYS;
  @Input() multiple = false;
  @Input() size: SelectSize = DEFAULT_SELECT_SIZE;
  @Input() grouped = false;
  @Input() onlyValue = false;
  @Input() chipsRequired = false;
  @Input() set listWithChips(value: boolean) {
    this._listWithChips = value
    this.multiple = this.multiple || value
  }
  @Input() loadSuccess: Observable<boolean> = new Observable<boolean>();

  private _config: SelectConfig = DEFAULT_SELECT_CONFIG;
  @Input() set config(value: SelectConfig) {
    this._config = { ...DEFAULT_SELECT_CONFIG, ...value };
    // copies no undefined or null values to local properties from config.
    ['showMultipleChips', 'showTotalsChip', 'maxNumberOfChips', 'multipleLabel', 'allSelectedLabel']
      .filter((key) => this._config[key] !== undefined || this._config[key] !== null)
      .forEach((key) => {
        this[key] = this._config[key];
      });
  }

  get config(): SelectConfig {
    return this._config;
  }

  /**
   * don't use this property to get selected value, instead use control.
   *  */
  selection: SelectionModel<T> = new SelectionModel<T>(true, []);
  @Output() selectionChanged: EventEmitter<Array<T> | T> = new EventEmitter();
  @Output() sendSelection: EventEmitter<Array<T> | T> = new EventEmitter();

  @ViewChild(CdkVirtualScrollViewport) viewPort: CdkVirtualScrollViewport;
  @ViewChild('list') list: ElementRef;
  @ViewChildren('option') options: QueryList<ElementRef<HTMLLIElement>>;
  @ViewChild('button') button: ElementRef;
  @ViewChild('input') input: ElementRef;

  searchTerm = '';
  searchTerm$: Subject<string> = new Subject();
  destroySubject$ = new Subject<void>();

  showMultipleChips = true;
  showTotalsChip = true;
  maxNumberOfChips = -1;
  multipleLabel = 'Multiple';
  allSelectedLabel = 'All Selected';
  _listWithChips = false;
  isExternalLoading = false;
  selectList: T[] = [];

  constructor(
    @Optional() @Self() protected ngControl: NgControl,
    injector: Injector,
    private readonly _cd: ChangeDetectorRef,
    private readonly _renderer: Renderer2
  ) {
    super(ngControl, injector);
  }

  ngOnInit() {
    this.id = generateId(this.label);
    this.loading = this.initLazyLoader(this._dataSource);

    if(this.multiple && this.config.includeAll && this.config.allSelectedActiveByDefault){
      this.activeAllSelectByDefault();
    }
  }

  ngAfterViewInit() {
    this.initScrollIndexSubscription();
    this.setSubscriptions(this._dataSource);
    this.loadSuccess
      .pipe(takeUntil(this.destroySubject$))
      .subscribe((value) => {
        this.isExternalLoading = false;

        if(value) {
          this.selectList = this.selection.selected;
          this.closeList();
        }
      });
  }

  activeAllSelectByDefault(){
    this.toggleAll();
    this.emitAllSelection();
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
    this.destroySubject$.next();
    this.destroySubject$.complete();
  }

  setSubscriptions(dataSource: SelectDatasource<T>) {
    this.setHeight(this.itemHeight, this.viewportItems, dataSource, Number(this.multiple && this.config.includeAll));
    this._subscriptions.add(this.initSearchDebounce(this.searchTerm$, dataSource));
  }

  initLazyLoader(dataSource: SelectDatasource<T>): Observable<boolean> {
    return dataSource instanceof LazyScrollableDataSource ? dataSource.getLoadingObservable() : of(false);
  }

  initScrollIndexSubscription() {
    this.viewPort.scrolledIndexChange.pipe(takeUntil(this.destroySubject$)).subscribe((index: number) => {
      this.index = index;

      if (this.allSelected && this._dataSource instanceof LazyScrollableDataSource) {
        this.toggleAll();
      }
    });
  }

  initSearchDebounce(observable: Observable<string>, dataSource: SelectDatasource<T>): Subscription {
    return observable
      .pipe(takeUntil(this.destroySubject$), debounceTime(600), distinctUntilChanged())
      .subscribe((value: string) => {
        if (dataSource instanceof LazyScrollableDataSource) {
          dataSource.setFilterValue(value);
        }

        this.scrollToIndex(0);
      });
  }

  scrollToIndex(index: number) {
    this.viewPort.scrollToIndex(index, 'smooth');
  }

  setHeight(
    itemHeight: number,
    maxViewportItems: number,
    dataSource?: SelectDatasource<T> | undefined | null,
    extraHeight = 0
  ) {
    if (dataSource instanceof LazyScrollableDataSource) {
      const subscription: Subscription = dataSource
        .getDataObservable()
        .pipe(takeUntil(this.destroySubject$))
        .subscribe((elems: T[]) => {
          this.viewportHeight = Math.min(maxViewportItems, elems.length + extraHeight) * itemHeight || itemHeight;
          this._cd.detectChanges();
        });
      this._subscriptions.add(subscription);
    } else {
      const dataSourceLength = this.grouped
        ? (dataSource as Array<SelectGroupedItem<T>>).reduce((acc, groupedItem) => {
            return acc + groupedItem.items.length + 1;
          }, 0)
        : dataSource?.length;

      this.viewportHeight = Math.min(maxViewportItems, dataSourceLength) * itemHeight || itemHeight;
      this._cd.detectChanges();
    }
  }

  closeList(event?: MouseEvent) {
    event?.stopPropagation();

    this.opened = false;
    this._modifyAttr(false, this.ariaActive, null, ...this.options);

    if (this.searchTerm) {
      this.searchTerm$.next('');
      this.searchTerm = '';
    }

    this.blur();

    if(this._listWithChips) {
      this.clearSelection();
      this.selectList.forEach(value => {
        this._toggleSelection(this.selection, value);
        this.defaultValue = this.selection.selected[0];
        this.control.setValue(this.selection.selected);
      });
    }
    this._cd.detectChanges();
  }

  openList() {
    this.opened = true;
    this._cd.detectChanges();
  }

  blur() {
    if (!this.opened && !this.control.touched) {
      this.control.markAsTouched();
    }
  }

  setSelection(value: T) {
    this.defaultValue = value;
    this.control.setValue(value);
    this.selectionChanged.emit(value);
    this.closeList();
  }

  setMultipleSelection(value: T, event?: MouseEvent) {
    event?.stopPropagation();

    this._toggleSelection(this.selection, value);

    this.defaultValue = this.selection.selected[0];
    this.control.setValue(this.selection.selected);
    this.selectionChanged.emit(this.control.value);
  }

  clearSelection() {
    if (this._allSelected) {
      this._allSelected = false;
    }

    this.selection.clear();

    this.defaultValue = null;
    this.control.setValue(this.selection.selected);
    this.selectionChanged.emit(this.control.value);
  }

  isAllSelected(dataSource: LazyScrollableDataSource<T> | T[]): boolean {
    return this._allSelected;
  }

  toggleAll() {
    this._allSelected = !this._allSelected;

    if (this._allSelected) {
      this.defaultValue = 'all';
      this.selection.clear();
    } else {
      this.defaultValue = null;
      this.selection.clear();
    }

    this.control.setValue(this.selection.selected);
    this._cd.detectChanges();
  }

  emitAllSelection() {
    this.selectionChanged.emit(this.control.value);
  }

  // Code for accesibility
  @HostListener('window:click', ['$event']) closeByClick(event: MouseEvent) {
    if (this.opened && event.target !== this.button.nativeElement) {
      this.closeList();
    }
  }

  openByKeyboard(e?: KeyboardEvent) {
    e?.preventDefault();

    if (!this.opened) {
      this.openList();
    }
  }

  navigateOnList(change: number, borderCondition: number, event?: KeyboardEvent) {
    event?.stopPropagation();

    const activeIndex = this.options
      .toArray()
      .findIndex(({ nativeElement }) => nativeElement.getAttribute(this.ariaActive));
    const active = this.options.toArray()[activeIndex];

    if (activeIndex !== borderCondition) {
      if (active) {
        this._modifyAttr(false, this.ariaActive, null, active);
      }

      const next = this.options.toArray()[activeIndex + change];
      this._modifyAttr(true, this.ariaActive, 'true', next);

      this.scrollToIndex(activeIndex > -1 ? this.index + change : 0);
    }
  }

  setSelectionOnNavigation(multiple: boolean, event?: KeyboardEvent) {
    event?.stopPropagation();

    const selected = this._findInOptions(this.options, this.ariaActive);
    const data = JSON.parse(selected.getAttribute(this.valueAttr));

    if(!data?.disabled) {
      if (multiple) {
        if (selected.getAttribute('id') === this.id + this.allAttr) {
          this.toggleAll();
        } else {
          this.setMultipleSelection(data);
        }
      } else {
        this.setSelection(data);
      }
    }
  }

  search(term: string) {
    this.searchTerm$.next(term);
  }

  // Utils
  private _modifyAttr(add: boolean, attr: string, value: string, ...list: (ElementRef | Element)[]) {
    list.forEach((elem: ElementRef | Element) => {
      const el: HTMLLIElement = (elem as ElementRef).nativeElement || elem;
      add ? this._renderer.setAttribute(el, attr, value) : this._renderer.removeAttribute(el, attr);
    });
  }

  private _toggleSelection(selection: SelectionModel<T>, item: T) {
    const isSelected = this.selectionIsSelected(selection, item);

    if (isSelected) {
      this._selectionDeselect(selection, item);
      this._allSelected = false;
    } else {
      this._selectionSelect(selection, item);
    }
  }

  private _selectionSelect(selection: SelectionModel<T>, item: T) {
    selection.select(...selection.selected, item);
    this.control.setValue(selection.selected);
  }

  private _selectionDeselect(selection: SelectionModel<T>, item: T) {
    selection.deselect(selection.selected.find(
      (e: T) => JSON.stringify(e) === JSON.stringify(this.mapSelected(e,item))
    ));
    this.control.setValue(selection.selected);
  }

  mapSelected(select: T, item: T): T {
    return Object.keys(select).reduce((value, key) => ({
      ...value,
      [key]: item[key]
    }), {} as T);
  }

  private _selectionSelectAll(selection: SelectionModel<T>, dataSource: LazyScrollableDataSource<T> | Array<T>) {
    const allElemens = dataSource instanceof LazyScrollableDataSource ? dataSource.getData() : dataSource;
    allElemens.forEach((el: T) => {
      if (!this.selectionIsSelected(selection, el)) {
        this._selectionSelect(selection, el);
      }
    });
  }

  private _findInOptions(options: QueryList<ElementRef<HTMLLIElement>>, attr: string): HTMLLIElement {
    return options.find(({ nativeElement }: { nativeElement: HTMLLIElement }) =>
      Boolean(nativeElement.getAttribute(attr))
    )?.nativeElement;
  }

  selectionIsSelected(selection: SelectionModel<T>, item: T): boolean {
    return Boolean(selection.selected.find((e: T) => e[this.keys.id] === item[this.keys.id]));
  }

  valueIsFormControlValue(formControl: FormControl, item: T): boolean {
    return Boolean(JSON.stringify(formControl.value) === JSON.stringify(item));
  }

  getOptionList = ([item]: [T]): T => {
    const data: T[] = (Array.isArray(this._dataSource) ?  this._dataSource : this._dataSource['_cachedData']) as T[];

    return data.find(el => el[this.keys.id] === item[this.keys.id])
  }
}
