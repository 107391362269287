import {
  Component,
  Input,
  TemplateRef,
  ElementRef,
  ViewEncapsulation,
  HostListener,
  Output,
  EventEmitter
} from '@angular/core';
import { ToolTipPositions } from '../definitions/enums';

@Component({
  selector: 'one-custom-tooltip-content',
  templateUrl: './custom-tooltip-content.component.html',
  styleUrls: ['./custom-tooltip-content.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomTooltipContentComponent {
  @Input() text: string;
  @Input() tooltipPosition = ToolTipPositions.right;
  @Input() contentTemplate: TemplateRef<ElementRef>;
  @Input() contextData: any;
  @Output() isHover = new EventEmitter<boolean>();

  @HostListener('mouseenter')
  public hoverIn() {
    this.isHover.emit(true);
  }

  @HostListener('mouseleave')
  public hoverOut() {
    this.isHover.emit(false);
  }
}
