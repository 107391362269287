import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardComponent } from './components/card/card.component';
import { DataTitleComponent } from './components/data-title/data-title.component';
import { CustomTooltipModule } from '../custom-tooltip/custom-tooltip.module';

@NgModule({
  declarations: [CardComponent, DataTitleComponent],
  imports: [CommonModule, CustomTooltipModule],
  exports: [CardComponent, DataTitleComponent]
})
export class CardModule {}
