export const BODY_REQUEST = {
  table: {},
  period: {
    load: false,
    current: {},
    previous: {},
    equalsPeriods: false,
    disabledBtn: false
  },
  loading: true,
  user: {
    tier_name: '',
    actions: {
      account_name: ''
    }
  },
  enableUpgradeButton: false,
  retryEnabled: false
};

export const STORAGE_KEY = {
  token: 'token',
  id: 'id',
  email: 'email',
  fullName: 'fullName',
  country: 'country',
  countries: 'countries',
  actions: 'actions',
  language: 'language',
  banners: 'banners',
  learningNotifications: 'learning',
  urlNewModulesVisited: 'urlNewModulesVisited'
};

export const TagManagerLayer = 'dataLayer';
export const EventSetUser = 'amplitudeSetUserId';
export const EventName = 'triggerAmplitudeEvent';
export const PrefixEvent = 'bbr_';