import { Pipe, PipeTransform } from '@angular/core';
import { MOBILE_WIDTH } from '../../definitions/dynamic-table.constants';
import { CellsConfig } from '../../definitions/dynamic-table.models';

@Pipe({
  name: 'appFilterMobileRows'
})
export class FilterMobileRowsPipe implements PipeTransform {
  transform(
    rows: Array<string>,
    config: CellsConfig,
    screenWidth: number
  ): Array<string> {
    return screenWidth > MOBILE_WIDTH
      ? rows
      : (rows || []).filter(
          (row, i) => !config[Object.keys(config)[i]].hideOnMobile
        );
  }
}
