export enum DynamicTableCellType {
  text = 'text',
  link = 'link',
  route = 'route',
  dialog = 'dialog',
  iconDialog = 'iconDialog',
  checkbox = 'checkbox',
  editable = 'editable',
  dropdown = 'dropdown',
  dropdownMultiple = 'dropdownMultiple',
  component = 'component',
  image = 'image',
  customComponent = 'customComponent',
  expandableComponent = 'expandableComponent',
  iconText = 'iconText',
  templateRef = 'templateRef'
}

export enum TableMessageType {
  checkboxMessage = 'checkbox',
  dropdownMessage = 'dropdown',
  dropdownMultiMessage = 'dropdownMulti',
  openDialog = 'openDialog',
  normalMessage = 'normal',
  editMessage = 'edit',
  rowSelectedMessage = 'rowSelected',
  customComponentMessage = 'customComponent',
  updateCustomComponent = 'updateCustomComponent',
  expandableComponentMessage = 'expandableComponent',
  updateExpandableComponent = 'updateExpandableComponent',
  toTableMessage = 'toTable',
  toastrMessage = 'link',
  change = 'change'
}

export enum TableCellAlign {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center'
}

export enum ToolTipPositions {
  top = 'top',
  right = 'right',
  bottom = 'bottom',
  left = 'left'
}
