import { Component, EventEmitter, Input, Output } from '@angular/core';

import { SafeStyle } from '@angular/platform-browser';

import { NestedTreeControl } from '@angular/cdk/tree';

import { MatTreeNestedDataSource } from '@angular/material/tree';
import { SelectionModel } from '@angular/cdk/collections';

import { TreeNode } from '../../definitions';

@Component({
  selector: 'one-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss']
})
export class TreeComponent {
  treeControl: NestedTreeControl<TreeNode>;
  dataSource: MatTreeNestedDataSource<TreeNode>;
  selection = new SelectionModel<number>(true, []);

  @Input() recursive = false;
  @Input() readonly = false;
  @Input() columns: SafeStyle = 1;
  @Input() showCountChildsActive = false;
  @Input() showCountAllChildsActiveInTree = false;

  @Input() set data(d: TreeNode[]) {
    this.dataSource.data = d;
  }

  @Input() set initialSelection(d: TreeNode[]) {
    this.setInitialSelection(d);
  }

  @Output() selectionChanged = new EventEmitter<number[]>();

  constructor() {
    this.treeControl = new NestedTreeControl<TreeNode>(this._getChildren);
    this.dataSource = new MatTreeNestedDataSource();
  }

  hasChild = (_: number, node: TreeNode) => node.children?.length;

  isAllDescendantsSelected(node: TreeNode): boolean {
    const descendants = this.treeControl.getDescendants(node);

    return descendants.every(({ id }: TreeNode) => this.selection.isSelected(id));
  }

  isDescendantsPartiallySelected(node: TreeNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(({ id }: TreeNode) => this.selection.isSelected(id));

    return result && !this.isAllDescendantsSelected(node);
  }

  isNoOneDescendantsSelected(node: TreeNode): boolean {
    const descendants = this.treeControl.getDescendants(node);

    return descendants.every(({ id }: TreeNode) => !this.selection.isSelected(id));
  }

  async toggleItemSelection(node: TreeNode): Promise<void> {
    this.selection.toggle(node.id);

    const descendants: TreeNode[] = this.treeControl.getDescendants(node);

    if (this.selection.isSelected(node.id)) {
      this.selection.select(...descendants.map(({ id }: TreeNode) => id));
    } else {
      this.selection.deselect(...descendants.map(({ id }: TreeNode) => id));
    }

    await this._checkAllParentsSelection(node);

    this.selectionChanged.emit(this.selection.selected);
  }

  async toggleLeafItemSelection(node: TreeNode): Promise<void> {
    this.selection.toggle(node.id);

    await this._checkAllParentsSelection(node);

    this.selectionChanged.emit(this.selection.selected);
  }

  setInitialSelection(selection: TreeNode[]): void {
    selection.forEach((elem: TreeNode) => {
      this.toggleItemSelection(elem);
    });
  }

  getChildrenSelected(children: TreeNode[], selected: number[]): number {
    const childrenIds = children.map(element => element.id);
    return childrenIds.filter(element => selected.includes(element)).length;
  }

  getChildrenSelectedAllChilds(childrens: number[], selected: number[]): number {
    return childrens.filter(element => selected.includes(element)).length;
  }

  getCountAllChilds(tree: TreeNode[]): number[] {
    let ids: number[] = [];
    for (const elemento of tree) {
      ids.push(elemento.id);
      if (elemento.children) {
        ids = ids.concat(this.getCountAllChilds(elemento.children));
      }
    }
    return ids;
  }

  private readonly _getChildren = (node: TreeNode): TreeNode[] => node.children;

  private _checkAllParentsSelection(node: TreeNode): Promise<void> {
    return new Promise((resolve: () => void) => {
      const ascendantAncestors: TreeNode[] = (this._getAncestors(this.dataSource.data, node.id) || []).reverse();

      for (const ancestor of ascendantAncestors) {
        this._checkRootNodeSelection(ancestor);
      }

      resolve();
    });
  }

  private _checkRootNodeSelection(node: TreeNode): void {
    const isNodeSelected: boolean = this.selection.isSelected(node.id);
    const isAlldescSelected: boolean = this.isAllDescendantsSelected(node);
    const isNoOneDescendantsSelected = this.isNoOneDescendantsSelected(node);

    if (isNodeSelected && isNoOneDescendantsSelected) {
      this.selection.deselect(node.id);
    } else if (!isNodeSelected && !isNoOneDescendantsSelected) {
      this.selection.select(node.id);
    }
  }

  private _getAncestors(data: TreeNode[], id: number): TreeNode[] | null {
    for (const node of data || []) {
      if (node.children?.find((child) => child.id === id)) {
        return [node];
      }

      const ancestors = this._getAncestors(node.children, id);
      if (ancestors !== null) {
        return [node, ...ancestors];
      }
    }

    return null;
  }
}
