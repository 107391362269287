import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'functionExecution'
})
export class FunctionExecutionPipe implements PipeTransform {

  transform(functionRun: (...args: unknown[]) => unknown, ...args: unknown[]): unknown {
    return functionRun(args);
  }
}
