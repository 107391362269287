import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FunctionExecutionPipe } from './function-execution.pipe';

@NgModule({
  declarations: [FunctionExecutionPipe],
  exports: [FunctionExecutionPipe],
  imports: [CommonModule]
})
export class FunctionExecutionModule {}
