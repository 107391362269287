import { ValidationErrors } from '@angular/forms';

export const errorsMapper = (formControlErrors: ValidationErrors): string[] => {
  const errors: string[] = Object.keys(formControlErrors || {});

  return errors.map((err: string) => {
    switch (err) {
      case 'required':
        return 'This field is required';
      case 'email':
        return 'Enter a valid email';
      case 'minLength':
        return `The min length is: ${formControlErrors[err].requiredLength}, actual: ${formControlErrors[err].actualLength}`;
      case 'maxLength':
        return `The max length is: ${formControlErrors[err].requiredLength}, actual: ${formControlErrors[err].actualLength}`;
      case 'pattern':
        return "This pattern doesn't match";
      default:
        return 'This field is invalid';
    }
  });
};
