import {
  AfterContentInit,
  Component,
  ContentChildren,
  ElementRef,
  HostBinding,
  OnDestroy,
  QueryList,
  ViewChild,
  Input
} from '@angular/core';
import { DataTitleComponent } from '../data-title/data-title.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'one-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements AfterContentInit, OnDestroy {
  @HostBinding('class.collapsed') collapsed = false;

  @ContentChildren(DataTitleComponent)
  public dataTitleComponents: QueryList<DataTitleComponent>;

  @ViewChild('oneCardContent')
  public oneCardContent: ElementRef<HTMLElement>;
  @Input() notBoxShadow = false;
  @Input() notPadding = false;
  @Input() marginTop: number;
  @Input() marginBottom: number;
  @Input() marginLeft: number;
  @Input() marginRight: number;

  public destroySubject$: Subject<void> = new Subject<void>();

  public ngAfterContentInit(): void {
    this.dataTitleComponents.forEach((template: DataTitleComponent) => {
      template.toggle.pipe(takeUntil(this.destroySubject$)).subscribe((collapsed: boolean) => {
        this.collapsed = collapsed;
        this.oneCardContent.nativeElement.style.display = collapsed ? 'none' : 'block';
      });
    });
  }

  public ngOnDestroy(): void {
    this.destroySubject$.next();
    this.destroySubject$.complete();
  }
}
