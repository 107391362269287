import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'thousandAbbreviation'
})
export class ThousandAbbreviationPipe implements PipeTransform {

  suffixes = ['K', 'M', 'Bn', 'Tn'];

  transform(input: number, args = 0): string | number {
    if (isNaN(input) || input < 1000) {
      return input;
    }
    const exp = Math.floor(Math.log(input) / Math.log(1000));
    return (input / Math.pow(1000, exp)).toFixed(args) + this.suffixes[exp - 1];
  }
}
