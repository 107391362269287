import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FeatureFlags, StandardResponse } from './definitions/interfaces';


@Injectable({
  providedIn: 'root'
})
export class FeatureFlagsService {
  readonly url = 'api/cauth/revenue/access/proxy/adv-bbr-gateway-int/configuration/';

  private baseUrls = {
    dev: 'https://microservices.dev.rappi.com',
    co: 'https://services.grability.rappi.com',
    cl: 'https://services.rappi.cl',
    br: 'https://services.rappi.com.br',
    ar: 'https://services.rappi.com.ar',
    pe: 'https://services.rappi.pe',
    mx: 'https://services.mxgrability.rappi.com',
    cr: 'https://services.rappi.co.cr',
    ec: 'https://services.rappi.com.ec',
    uy: 'https://services.rappi.com.uy'
  };

  constructor(private _http: HttpClient) {}

  getConfig(url: string): Observable<FeatureFlags> {
    return this._http.get<StandardResponse<FeatureFlags>>(`${url}feature-flags`)
      .pipe(map(response => response.data));
  }

  checkFeatureEnabled(path: string, key: string, country: string): Observable<boolean> {
    const url = `${ this.getBaseUrl(country)}/${this.url}`;
    return this.getConfig(url).pipe(
      map(configRes => !!configRes[path]?.[key]?.[country.toLowerCase()])
    );
  }

  getBaseUrl(country: string): string {
    return this.baseUrls[country.toLowerCase()] || '';
  }
}
