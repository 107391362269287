import { ControlContainer, ControlValueAccessor, FormControl, NgControl, FormGroupDirective } from '@angular/forms';
import { Injector, Optional, Self, HostBinding, Input, Directive, Output, EventEmitter } from '@angular/core';

export type FunctionParam<T> = (value?: T) => {};
export type FunctionType = () => {};

@Directive()
export class ControlValueAccessorConnector<T> implements ControlValueAccessor {
  @HostBinding('class.form-submitted') get submitted(): boolean {
    return this.controlContainer?.submitted;
  }

  get controlContainer(): FormGroupDirective {
    try {
      return this.injector.get(ControlContainer) as FormGroupDirective;
    } catch (error) {
      return null;
    }
  }

  @Output() change = new EventEmitter<T>();

  @Input() set disabled(isDisabled: boolean) {
    this._disabled = isDisabled;
  }
  get disabled(): boolean {
    return this._disabled;
  }

  @Input() set value(newValue: T) {
    this.writeValue(newValue);
    this.onChange(this._value);
    this.onTouch();
  }
  get value(): T {
    return this._value;
  }

  @Input() set touched(isTouched: boolean) {
    this._touched = isTouched;
  }
  get touched(): boolean {
    return this._touched;
  }

  control: FormControl;

  protected _touched: boolean;
  protected _value: T;
  private _disabled: boolean;

  constructor(@Optional() @Self() protected ngControl: NgControl, protected readonly injector: Injector) {
    this.initNgControl(ngControl);
  }

  initNgControl(ngControl: NgControl): void {
    if (ngControl) {
      this.control = ngControl.control as FormControl;
      ngControl.valueAccessor = this;
    } else {
      this.control = new FormControl();
    }
  }

  registerOnTouched(fn: FunctionType): void {
    this.onTouch = fn;
  }

  registerOnChange(fn: FunctionParam<T>): void {
    this.onChange = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  writeValue(value: T): void {
    this._value = value;
  }

  private onChange = (newValue: T) => {  this.change.emit(newValue); };
  private onTouch = () => {};
}
