import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef,
  ViewChildren,
  QueryList,
  ComponentRef,
  ComponentFactoryResolver,
  Inject,
  ViewEncapsulation
} from '@angular/core';
import {
  DynamicTableData,
  DynamicTableRow,
  CellValue,
  ComponentProperties,
  TableMessage
} from '../../definitions/dynamic-table.models';
import { takeUntil, tap, debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { Subject, Observable, fromEvent, isObservable } from 'rxjs';
import { TableMessageType, TableCellAlign, ToolTipPositions } from '../../definitions/dynamic-table.enums';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DynamicComponentDirective } from '../../resources/directives/dynamic-component.directive';
import { LazyTableDataSource } from '../../definitions/lazy-table-data-source.class';
import { MOBILE_WIDTH } from '../../definitions/dynamic-table.constants';

// @dynamic
@Component({
  selector: 'one-dynamic-table',
  styleUrls: ['dynamic-table.component.scss'],
  templateUrl: 'dynamic-table.component.html',
  providers: [{ provide: 'Window', useValue: window }],
  encapsulation: ViewEncapsulation.None
})
export class DynamicTableComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren(DynamicComponentDirective) expandableComponentsRefs: QueryList<DynamicComponentDirective>;
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Input() tableData: DynamicTableData;
  readonly MOBILE_WIDTH = MOBILE_WIDTH;
  destroySubject$: Subject<void> = new Subject();
  minWidth = 75;
  checked = false;
  screenWidth;
  dataLoading: Observable<boolean> = new Observable();
  filterTextChanged$: Subject<string> = new Subject();
  expandedElement: DynamicTableRow;
  tableAlineation = TableCellAlign;
  tooltipAlineation = ToolTipPositions;
  expandableRow = ['expandableRow'];
  inputsKey = 'inputs';
  outputsKey = 'outputs';
  VISIBLE = 'visible';
  HIDDEN = 'hidden';
  flagLoading = true;
  private currentPageSize = 0;

  constructor(
    private readonly cd: ChangeDetectorRef,
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    @Inject('Window') private readonly window: Window
  ) {
    this.screenWidth = this.window.innerWidth;
  }

  ngOnInit() {
    if (this.tableData && !this.tableData.lazyLoaded) {
      (this.tableData.dataSource as MatTableDataSource<DynamicTableRow>).filterPredicate = (
        data: DynamicTableRow,
        filterV: string
      ) =>
        !filterV ||
        Object.keys(data).reduce((acc, key) => {
          const val = (data[key] as CellValue).value;
          return (val && String(val).toLowerCase().includes(filterV)) || acc;
        }, false);
    }

    Object.keys(this.tableData?.cellsConfig || {}).forEach((key) => {
      if (this.tableData.cellsConfig[key]?.allHeader) {
        this.checkSelectedRows(key);
        if (this.tableData.cellsConfig[key].subject) {
          this.tableData.cellsConfig[key].subject.pipe(takeUntil(this.destroySubject$)).subscribe(() => {
            this.checkSelectedRows(key);
          });
        }
      }
    });

    fromEvent(window, 'resize')
      .pipe(debounceTime(800), takeUntil(this.destroySubject$), distinctUntilChanged())
      .subscribe(() => (this.screenWidth = this.window.innerWidth));

    this.initSearchDebounce();
  }

  ngAfterViewInit() {
    if (this.tableData && !this.tableData.lazyLoaded) {
      (this.tableData.dataSource as MatTableDataSource<DynamicTableRow>).paginator = this.paginator;
    } else if (this.tableData && this.paginator) {
      this.tableData.paginator = this.paginator;
      const dataS = this.tableData.dataSource as LazyTableDataSource<DynamicTableRow>;
      dataS.paginator = this.paginator;
      this.currentPageSize = this.paginator.pageSize;
      dataS.getPageObservable()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe((page: number) => {
        if (this.paginator.pageIndex !== page) {
          this.paginator.pageIndex = page;
        }

        if (!this.tableData.dataSource.data.length && this.paginator.length) {
          this.paginator.length = 0;
        }
      });

      this.subscriptionPaginator(this.paginator, dataS.initPage);
    }

    if (this.tableData?.expandableRows) {
      if (this.tableData.cellsConfig[this.tableData.expandableComponent.name].subject) {
        this.tableData.cellsConfig[this.tableData.expandableComponent.name].subject
          .pipe(
            takeUntil(this.destroySubject$),
            filter((msg: TableMessage) => msg.type === TableMessageType.updateExpandableComponent)
          )
          .subscribe((msg: TableMessage) => {
            this.initializeExpandableComponent(
              this.expandableComponentsRefs.toArray()[msg.data.id],
              this.tableData.dataSource.filteredData[msg.data.id]
            );
          });
      }

      this.expandableComponentsRefs.changes
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(() => {
        this.iterateOverExpandableComponents(this.paginator.pageIndex);
        this.cd.detectChanges();
      });
    }

    this.initLoader();
  }

  subscriptionPaginator(paginator: MatPaginator, initPage: number): void {
    paginator.page
      .pipe(
        takeUntil(this.destroySubject$),
        tap((res: PageEvent) => {
          this.iterateOverExpandableComponents(res.pageIndex);
          const dataRef = this.tableData.dataSource as LazyTableDataSource<DynamicTableRow>;
          if (this.currentPageSize === res.pageSize) {
            dataRef.loadElements(res.pageIndex + initPage);
          } else {
            this.currentPageSize = res.pageSize;
            dataRef.setPageSize(this.currentPageSize);
          }
        })
      )
      .subscribe();
  }

  iterateOverExpandableComponents(pageIndex: number) {
    this.expandableComponentsRefs.toArray().forEach((componentRef, i) => {
      const index = pageIndex * this.paginator.pageSize + i;
      if (this.tableData.dataSource.filteredData[index]) {
        this.initializeExpandableComponent(componentRef, this.tableData.dataSource.filteredData[index]);
      } else {
        componentRef.viewContainerRef.clear();
      }
    });
  }

  initializeExpandableComponent(customComponentRef: DynamicComponentDirective, element: DynamicTableRow) {
    const componentProps = element as CellValue;
    const configProps = this.tableData.expandableComponent;
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(configProps.component);
    const viewContainerRef = customComponentRef.viewContainerRef;
    viewContainerRef.clear();
    const componentRef = viewContainerRef.createComponent(componentFactory);
    this.setComponentProperties(configProps, componentProps, componentRef, this.inputsKey);
    this.setComponentProperties(configProps, componentProps, componentRef, this.outputsKey);
  }

  setComponentProperties(
    configProps: ComponentProperties,
    componentProps: CellValue,
    componentRef: ComponentRef<any>,
    type: string
  ) {
    const name: string = configProps.name;
    if (componentProps && configProps[type].length) {
      configProps[type].forEach((prop) => {
        if (Object.keys(componentProps[name][type]).includes(prop)) {
          componentRef.instance[prop] = componentProps[name][type][prop];
        }
      });
    }
  }

  initSearchDebounce() {
    this.filterTextChanged$
      .pipe(takeUntil(this.destroySubject$), debounceTime(800), distinctUntilChanged())
      .subscribe((value) => {
        if (this.tableData.lazyLoaded) {
          (this.tableData.dataSource as LazyTableDataSource<DynamicTableRow>).setFilterValue(value);
        } else {
          (this.tableData.dataSource as MatTableDataSource<DynamicTableRow>).filter = value.trim().toLowerCase();
        }
      });
  }

  initLoader() {
    if (isObservable(this.tableData?.customLoader)) {
      this.dataLoading = this.tableData.customLoader.pipe(filter((value: boolean) => typeof value === 'boolean'));
      this.cd.detectChanges();
    } else if (
      this.tableData &&
      typeof (this.tableData.dataSource as LazyTableDataSource<DynamicTableRow>).getLoadingObservable === 'function'
    ) {
      this.dataLoading = (this.tableData.dataSource as LazyTableDataSource<DynamicTableRow>).getLoadingObservable();
      this.cd.detectChanges();
    }

    this.dataLoading.pipe(takeUntil(this.destroySubject$)).subscribe(value=>{
      this.flagLoading = value;
    });
  }

  applyFilter(value: string) {
    this.filterTextChanged$.next(value);
  }

  checkSelectedRows(key: string) {
    this.checked =
      Boolean(this.tableData?.dataSource?.data.length) &&
      this.tableData.dataSource.data.reduce(
        (acc, row) => acc && Boolean(row.disabled || (row[key] as CellValue).value),
        true
      );
  }

  checkbox($event, column: string) {
    this.checked = $event.target.checked;
    this.tableData.dataSource.data.forEach((row) => {
      if(!row.disabled){
        (row[column] as CellValue).value = $event.target.checked;
      }
    });

    this.emitTableDataSubject({
      type: TableMessageType.change,
      data: { checked: this.checked }
    });
  }

  trackById(index: number, row: DynamicTableRow) {
    return row.id;
  }

  rowClick(i: number) {
    const index = this.paginator ? this.paginator.pageIndex * this.paginator.pageSize + i : i;
    const selectedRow: DynamicTableRow = this.tableData.dataSource.filteredData[index];

    if (this.tableData.selectableRows) {
      selectedRow.selected = !selectedRow.selected;

      this.emitTableDataSubject({
        type: TableMessageType.rowSelectedMessage,
        data: { index, selected: selectedRow.selected }
      });
    }

    if (this.tableData.rowClickFunction) {
      this.tableData.rowClickFunction(index, selectedRow);
    }

    if (this.tableData.expandableRows) {
      this.expandedElement = this.expandedElement === selectedRow ? null : selectedRow;
    }
  }

  emitTableDataSubject(data: TableMessage): void {
    if (this.tableData.subject) {
      this.tableData.subject.next(data);
    }
  }

  resetTable(data: Array<DynamicTableRow> = []) {
    if (this.tableData?.dataSource) {
      if (this.tableData.lazyLoaded) {
        (this.tableData.dataSource as LazyTableDataSource<DynamicTableRow>).resetTable();
      } else {
        this.tableData.dataSource.data = data;
      }
    }
  }

  getDataSource(): MatTableDataSource<DynamicTableRow> | LazyTableDataSource<DynamicTableRow> {
    return this.tableData?.dataSource;
  }

  getDataLength(): number {
    return this.tableData?.dataSource?.data.length;
  }

  getPagesNumber(): number {
    return this.paginator?.length;
  }

  getDataRow(index: number) {
    return index <= this.getDataLength() ? this.tableData?.dataSource?.data[index] : null;
  }

  setPaginator(paginator: MatPaginator) {
    this.paginator = paginator;
    if (this.tableData && !this.tableData.lazyLoaded) {
      (this.tableData.dataSource as MatTableDataSource<DynamicTableRow>).paginator = this.paginator;
    }
  }

  getPaginator(): MatPaginator {
    return this.paginator;
  }

  setDataLength(n: number) {
    if (this.tableData?.lazyLoaded) {
      this.paginator.length = n;
    }
  }

  setData(data: Array<DynamicTableRow>) {
    if (this.tableData?.dataSource && !this.tableData.lazyLoaded) {
      this.tableData.dataSource.data = data;
    }
  }

  ngOnDestroy() {
    this.destroySubject$.next();
    this.destroySubject$.complete();
  }

  lastSticky(i: number) {
    return (
      i ===
      Object.values(this.tableData.cellsConfig)
        .map((column) => (column.stickyMobile && this.screenWidth < MOBILE_WIDTH) || column.sticky)
        .lastIndexOf(true)
    );
  }
}
