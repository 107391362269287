import { Inject, Injectable } from '@angular/core';
import * as Crypto from 'crypto-js';
import { ENCRYPT_KEY } from './definitions/constants';

@Injectable({
  providedIn: 'root'
})
export class EncryptService {
  constructor(@Inject(ENCRYPT_KEY) private encryptKey: string) {}

  encryptString(text: string, key = this.encryptKey): string {
    return this._encrypt(text, key);
  }

  encryptObject(data: unknown, key = this.encryptKey): string {
    return this._encrypt(JSON.stringify(data), key);
  }

  decryptString(data: string, key = this.encryptKey): string {
    return this._decrypt(data, key);
  }

  decryptObject(data: string, key = this.encryptKey): unknown {
    return JSON.parse(this._decrypt(data, key)) as unknown;
  }

  private _encrypt(data: string, key: string): string {
    return Crypto.AES.encrypt(data, key).toString() as string;
  }

  private _decrypt(data: string, key: string): string {
    return Crypto.AES.decrypt(data, key).toString(Crypto.enc.Utf8) as string;
  }
}
