import { Component, Input } from '@angular/core';

import { BreadcrumbsService } from '../../services/breadcrumb.service';

@Component({
  selector: 'one-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent {
  @Input()
  showHome = false;

  @Input() prefix;

  constructor(public breadcrumbsService: BreadcrumbsService) {}
}
