import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomTooltipContentComponent } from './custom-tooltip-content/custom-tooltip-content.component';
import { TooltipRendererDirective } from './resources/directives/custom-tooltip.directive';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  imports: [CommonModule, MatDialogModule],
  declarations: [CustomTooltipContentComponent, TooltipRendererDirective],
  entryComponents: [CustomTooltipContentComponent],
  exports: [TooltipRendererDirective],
})
export class CustomTooltipModule {}
