import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { takeUntil, finalize } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';

export class LazyTableDataSource<T> extends DataSource<T> {
  public data: Array<T> = [];
  public filteredData: Array<T> = [];
  public totalElementsKey: string;
  public paginator: MatPaginator;
  private _pageSize = 0;
  private _filterValue = '';
  private readonly _pageSubject = new BehaviorSubject<number>(0);
  private _fetchedPages = new Set<number>();
  private readonly _dataStream = new BehaviorSubject<T[]>(this.data);
  private readonly _serviceWrapper: Function;
  private readonly _loadingSubject = new BehaviorSubject<boolean>(false);
  private readonly destroySubject$: Subject<void> = new Subject();

  /* istanbul ignore next */
  constructor(
    serviceWrapper: Function,
    pageSize = 5,
    filter = '',
    public initPage = 0
  ) {
    super();
    this._pageSize = pageSize;
    this._serviceWrapper = serviceWrapper;
    this._filterValue = filter;
  }

  connect(): Observable<T[]> {
    if (!this.data.length) {
      this._fetchPage(this.initPage);
    }
    return this._dataStream.asObservable();
  }

  disconnect(): void {
    this._dataStream.complete();
    this._loadingSubject.complete();
    this._pageSubject.complete();
    this.destroySubscriptions();
  }

  public loadElements(
    page = this.initPage,
    pageSize = this._pageSize,
    filter = this._filterValue
  ) {
    const start = (page - this.initPage) * this._pageSize;
    this._loadingSubject.next(true);

    if (!this._fetchedPages.has(page)) {
      this._fetchedPages.add(page);
      this._serviceWrapper(page, pageSize, filter || this._filterValue)
        .pipe(
          takeUntil(this.destroySubject$),
          finalize(() => {
            this._loadingSubject.next(false);
            this._pageSubject.next(page - this.initPage);
          })
        )
        .subscribe(
          (res: Array<T>) => {
            this.data = this.data.concat(res.filter((item) => item));
            this.filteredData = this.data;
            if (
              this.paginator &&
              this.data.length > 0 &&
              this.paginator.length <= 0
            ) {
              console.warn(
                'You should use the DynamicTable setDataLength method to set paginator length when the first data page arrive.'
              );
            }
            this.returnTablePage(start);
          },
          () => this._fetchedPages.delete(page)
        );
    } else {
      this.returnTablePage(start);
      this._loadingSubject.next(false);
      this._pageSubject.next(page - this.initPage);
    }
  }

  public resetTable() {
    this._resetValues();
    this.loadElements();
    this._dataStream.next(this.data);
  }

  returnTablePage(start: number) {
    this._dataStream.next(this.data.slice(start, start + this._pageSize));
  }

  public getLoadingObservable(): Observable<boolean> {
    return this._loadingSubject.asObservable();
  }

  public setPageSize(n: number) {
    this._pageSize = n;
    this.resetTable();
  }

  public getPageSize(): number {
    return this._pageSize;
  }

  public getDataLength() {
    return this.data.length;
  }

  public setDataLength(n: number) {
    if (this.paginator) {
      this.paginator.length = n;
    }
  }

  public getPageObservable(): Observable<number> {
    return this._pageSubject.asObservable();
  }

  public setFilterValue(value: string) {
    this._filterValue = value;
    this._resetValues();
    this._fetchPage(this.initPage);
  }

  public renderUpdate() {
    this._dataStream.next(this.data);
  }

  public removeRow(index: number) {
    const currentPage = Math.floor(index / this._pageSize);
    const lowerBound = currentPage * this._pageSize;
    this.data.splice(lowerBound);
    this.filteredData = this.data;
    this._fetchedPages = new Set(
      Array(currentPage)
        .fill(null)
        .map((_, i) => i + this.initPage)
    );
    this._fetchPage(currentPage);
  }

  public destroySubscriptions() {
    this.destroySubject$.next();
    this.destroySubject$.complete();
  }

  private _resetValues() {
    this.data = [];
    this.filteredData = [];
    this._fetchedPages = new Set<number>();
  }

  private _fetchPage(page: number) {
    this.loadElements(page);
  }
}
