export const RadioAppearance = {
  vertical: 'vertical',
  horizontal: 'horizontal',
  simple: 'simple',
};

export interface ParamsData {
  code: string;
  copy: string;
  description?: string;
  image?: string;
  checked?: boolean;
  disabled?: boolean;
  badge?: string;
}
