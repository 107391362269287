export enum RouteActions {
  download = 'download',
  link = 'link',
  report = 'report',
  modal = 'modal'
}

export enum RouteDataKeys {
  fileName = 'fileName',
  fileUrl = 'fileUrl',
  requestMethod = 'requestMethod',
  requestBody = 'requestBody',
  downloadTex = 'downloadTex',
  linkText = 'linkText',
  route = 'route',
  extras = 'extras',
  modalComponent = 'modalComponent',
  modalData = 'modalData',
  subtitle = 'subtitle',
  actionsPermissions = 'actionsPermissions'
}
