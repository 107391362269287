import { SelectSize } from '.';
import { SelectKeys, SelectConfig } from './select.models';

export const DEFAULT_SELECT_KEYS: SelectKeys = {
  id: 'id',
  name: 'name',
  disabled: 'disabled',
  displayProperty: 'name'
};

export const DEFAULT_SELECT_CONFIG: SelectConfig = {
  includeAll: false,
  panelClass: 'one-select',
  search: false,
  showMultipleChips: true,
  showTotalsChip: true,
  maxNumberOfChips: -1,
  multipleLabel: 'Multiple',
  allSelectedLabel: 'All Selected'
};

export const DEFAULT_SELECT_SIZE: SelectSize = 'default';
